import React from "react"

export function ShareIcon({ strokeColor = "black", strokeWidth = 1.5 }) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9023 8.90796H16.3534C16.9057 8.90796 17.3534 9.35567 17.3534 9.90796V16.2613C17.3534 16.8136 16.9057 17.2613 16.3534 17.2613H3.64673C3.09444 17.2613 2.64673 16.8136 2.64673 16.2613V9.90796C2.64673 9.35567 3.09444 8.90796 3.64673 8.90796H6.09784"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeMiterlimit="10"
      />
      <path
        d="M10 13.0596L10 3.08008"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeMiterlimit="10"
      />
      <path
        d="M6.84851 5.70636L9.99994 2.55493L13.1514 5.70636"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />
    </svg>
  )
}
