import React from "react"

export function DownloadIcon({ strokeColor = "black", strokeWidth = 1.5 }) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.6507 18.2305H10V18.2305C5.71566 18.2305 2.34937 18.2305 2.34937 18.2305H10V18.2305C14.2844 18.2305 17.6507 18.2305 17.6507 18.2305Z"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeMiterlimit="10"
      />
      <path
        d="M10 1.01657L10 13.0083"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeMiterlimit="10"
      />
      <path
        d="M14.2324 9.48125L10 13.7136L5.76767 9.48125"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />
    </svg>
  )
}
