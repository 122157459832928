import React from "react"
import styled from "styled-components"
//
import { paragraph18Styles } from "@constants/typography"
import breakpoints from "@constants/breakpoints"
import colors from "@constants/colors"

const StyledParagraph = styled.p`
  ${paragraph18Styles}
  font-weight: 400;
  color: ${colors.mediumDarkGray};
  white-space: pre-line;
`
const Container = styled.div`
  padding: 36px 0;
  @media ${breakpoints.tablet} {
    padding: 0 calc(1 / 12 * 100vw) 73px;
  }
`

interface ParagraphBlockProps {
  text: string
}

export function ParagraphBlock({ text }: ParagraphBlockProps) {
  return (
    <Container>
      <StyledParagraph>{text}</StyledParagraph>
    </Container>
  )
}
