import React from "react"
import styled from "styled-components"
//
import { h3Styles, h4Styles } from "@constants/typography"
import breakpoints from "@constants/breakpoints"

const StyledH3 = styled.h3`
  ${h4Styles}
  @media ${breakpoints.largerThanMobile} {
    ${h3Styles}
  }
`

interface H3BlockProps {
  text: string
}

const Container = styled.div`
  padding: 36px 0;
  @media ${breakpoints.tablet} {
    padding: 0 calc(1 / 12 * 100vw) 73px;
  }
`

export function H3Block({ text }: H3BlockProps) {
  return (
    <Container>
      <StyledH3>{text}</StyledH3>
    </Container>
  )
}
