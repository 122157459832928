import React from "react"

export function GalleryIcon({ strokeColor = "black", strokeWidth = 1.5 }) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.56489 1.74487H2.74487C2.19259 1.74487 1.74487 2.19259 1.74487 2.74487V6.56489C1.74487 7.11718 2.19259 7.56489 2.74487 7.56489H6.56489C7.11718 7.56489 7.56489 7.11718 7.56489 6.56489V2.74487C7.56489 2.19259 7.11718 1.74487 6.56489 1.74487Z"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeMiterlimit="10"
      />
      <path
        d="M15.2551 1.74487H11.4351C10.8828 1.74487 10.4351 2.19259 10.4351 2.74487V6.56489C10.4351 7.11718 10.8828 7.56489 11.4351 7.56489H15.2551C15.8074 7.56489 16.2551 7.11718 16.2551 6.56489V2.74487C16.2551 2.19259 15.8074 1.74487 15.2551 1.74487Z"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeMiterlimit="10"
      />
      <path
        d="M6.56489 10.4351H2.74487C2.19259 10.4351 1.74487 10.8828 1.74487 11.4351V15.2551C1.74487 15.8074 2.19259 16.2551 2.74487 16.2551H6.56489C7.11718 16.2551 7.56489 15.8074 7.56489 15.2551V11.4351C7.56489 10.8828 7.11718 10.4351 6.56489 10.4351Z"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeMiterlimit="10"
      />
      <path
        d="M15.2551 10.4351H11.4351C10.8828 10.4351 10.4351 10.8828 10.4351 11.4351V15.2551C10.4351 15.8074 10.8828 16.2551 11.4351 16.2551H15.2551C15.8074 16.2551 16.2551 15.8074 16.2551 15.2551V11.4351C16.2551 10.8828 15.8074 10.4351 15.2551 10.4351Z"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeMiterlimit="10"
      />
    </svg>
  )
}
