import React from "react"
import styled from "styled-components"
//
import { body28Styles, smallcapsBold13Styles } from "@constants/typography"
import colors from "@constants/colors"

const StyledQuote = styled.h3`
  ${body28Styles}
  font-weight: 400;
  margin-bottom: 32px;
`

const StyledAuthorText = styled.h3`
  ${smallcapsBold13Styles}
  color: ${colors.brandOrange}
`

const Container = styled.div`
  margin: 36px 0;
`

interface QuoteBlockProps {
  text: string
  authorName: string
}

export function QuoteBlock({ text, authorName }: QuoteBlockProps) {
  return (
    <Container>
      <StyledQuote>{text}</StyledQuote>
      <StyledAuthorText>- {authorName}</StyledAuthorText>
    </Container>
  )
}
