import React from "react"
import styled from "styled-components"
import Image, { FluidObject, GatsbyImageProps } from "gatsby-image"
import colors from "@constants/colors"
import breakpoints from "@constants/breakpoints"
import { body14Styles } from "@constants/typography"

// TODO: update margin system
const Container = styled.div`
  margin: 36px 0;
`

// padding: 0 20px 88px;

// @media ${breakpoints.tablet} {
//   padding: 0 calc(1 / 12 * 100vw) 88px;
// }

// @media ${breakpoints.laptop} {
//   padding: 0 calc(1 / 12 * 100vw) 178px;
// }

// width: calc(100% + 40px);
// height: calc(100vw - 40px);
const StyledImage = styled(Image)<GatsbyImageProps>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  object-fit: cover;
`

const CaptionWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  align-items: center;
`

const Caption = styled.div`
  text-align: center;
  width: calc(100% - 35px);
  font-family: "Avenir Roman";
  font-size: 15px;
  color: ${colors.black};
  line-height: 22px;
  opacity: 0.3;

  @media ${breakpoints.tablet} {
    ${body14Styles}
    color: ${colors.mediumGray};
    opacity: 1;
  }

  @media ${breakpoints.laptop} {
    ${body14Styles}
    color: ${colors.mediumGray};
    opacity: 1;
  }
`
const Overlay = styled.div<{ isVisible: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  will-change: opacity;
  pointer-events: none;
  opacity: 0;
  z-index: 2;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65));

  @media ${breakpoints.largerThanMobile} {
    right: -15px;
    width: calc(100% + 15px);
  }
`

interface ExploreImageProps {
  mobileImage?: FluidObject
  image: FluidObject
  caption?: string
}

const ExploreImage: React.FC<ExploreImageProps> = ({
  mobileImage,
  image,
  caption,
}) => {
  return image ? (
    <Container>
      <ExploreImageWrapper>
        <StyledImage
          fluid={mobileImage ? mobileImage : image}
          style={{ position: "absolute" }}
        />
      </ExploreImageWrapper>
      {caption && (
        <CaptionWrapper>
          <Caption>{caption}</Caption>
        </CaptionWrapper>
      )}
    </Container>
  ) : null
}

const ExploreImageWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-top: 56.25%;
`

export default ExploreImage
