import React from "react"
import styled from "styled-components"
import { BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import {
  useTriggerTransition,
  TransitionState,
} from "gatsby-plugin-transition-link"
//
import { H3Block } from "@components/BuildingBlocks/Headings/h3"
import { ParagraphBlock } from "@components/BuildingBlocks/Paragraph/p"
import ExploreImage from "@components/ExploreMedia/ExploreImage"
import { GalleryIcon } from "@components/icons/Gallery"
import { ShareIcon } from "@components/icons/Share"
import { Label } from "@components/Label"
import breakpoints from "@constants/breakpoints"
import {
  h2Styles,
  h4Styles,
  smallcapsBold14Styles,
} from "@constants/typography"
import Gallery from "../components/Gallery"
import { QuoteBlock } from "@components/BuildingBlocks/Quote/quote"
import ReflowBlock from "@components/ReflowBlock"
import Layout from "@components/Layout"
import Helmet from "@components/Helmet"
import { ListItem, UnorderedListBlock } from "@components/BuildingBlocks/List"
import colors from "@constants/colors"
import { graphql, useStaticQuery } from "gatsby"

const BlogTitle = styled.h1`
  ${h4Styles}

  @media ${breakpoints.largerThanMobile} {
    ${h2Styles}
  }
`

const ShareWrapper = styled.div<{ savedUrl: boolean }>`
  opacity: ${({ savedUrl }) => (savedUrl ? 0.4 : 1)};
`

const Container = styled.div<{
  mounted: boolean
  entryDirection: "FORWARD" | "REVERSE"
  exitDirection: "FORWARD" | "REVERSE"
}>`
  padding: 88px 20px 0;

  animation: ${({ mounted, entryDirection, exitDirection }) => {
      //  TODO: check if animation is changing and causing the issue with flashes
      // Old page: from translateY(0)  opacity 1 to translateY(-200) opacity: 0
      // New page: from translateY(200)  opacity 0 to translateY(0) opacity: 1
      if (mounted) {
        return "slideInFromBelow 0.2s"
      } else {
        return "slideOffTopOfPage 0.35s"
      }
    }}
    ease-in-out;
  background-color: white;

  @media ${breakpoints.tablet} {
    padding: calc(1 / 12 * 100vw) calc(1 / 12 * 100vw) 0;
  }

  @media ${breakpoints.laptop} {
    padding: calc(1 / 12 * 100vw) calc(1 / 12 * 100vw) 0;
  }

  @keyframes slideInFromBelow {
    from {
      transform: translateY(200px);
      opacity: 0;
    }

    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes slideOffTopOfPage {
    0% {
      transform: translateY(0);
      opacity: 1;
    }

    100% {
      transform: translateY(-200px);
      opacity: 0;
    }
  }

  > * {
    animation: fadeIn 1s ease;

    @keyframes fadeIn {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }
  }
`

const DateText = styled.p`
  ${smallcapsBold14Styles}
  margin: 20px 0;

  @media ${breakpoints.largerThanMobile} {
    margin: 32px 0;
  }
`

const BlogOptionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  > * {
    margin-bottom: 20px;
    margin-right: 32px;
  }
  > *:last-child {
    margin-right: 0;
  }
`

const ArticleWrapper = styled.article`
  margin: 10px 0 100px 0;

  @media ${breakpoints.largerThanMobile} {
    margin-bottom: 100px;
  }
`
const options = {
  renderNode: {
    [BLOCKS.QUOTE]: (node, children) => {
      const [
        author,
        quote,
      ]: string[] = node?.content[0].content[0].value?.split(": ")

      return <QuoteBlock authorName={author} text={quote} />
    },
    [BLOCKS.UL_LIST]: (node, children) => (
      <UnorderedListBlock children={children} />
    ),
    [BLOCKS.LIST_ITEM]: (node, children) => (
      <ListItem text={children[0].props.text} />
    ),
    [BLOCKS.PARAGRAPH]: (node, children) => <ParagraphBlock text={children} />,
    [BLOCKS.HEADING_1]: (node, children) => <H3Block text={children} />,
    [BLOCKS.EMBEDDED_ASSET]: (node, children) => (
      <ExploreImage
        key={node.data.target.description}
        image={node.data.target.fluid}
        caption={node.data.target.description}
      />
    ),
  },
}

function useNavigateToNextArticle(slug?: string) {
  const trigger = useTriggerTransition({
    to: "/" + slug,
    exit: {
      length: 0.35,
    },
    entry: {
      length: 0.2,
      delay: 0.35,
    },
  })
  return trigger
}

export default function ({
  pageContext: { post, next },
  entry,
  exit,
  location,
}) {
  const triggerNext = useNavigateToNextArticle(next?.slug)

  const [savedUrl, setSavedUrl] = React.useState(false)

  React.useEffect(() => {
    let timeout
    if (savedUrl) {
      timeout = setTimeout(() => {
        setSavedUrl(false)
      }, 2000)
    }

    if (timeout) {
      return () => clearTimeout(timeout)
    }
  }, [savedUrl])

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  )

  return (
    <Layout>
      <Helmet title={post.title} />
      <TransitionState>
        {({ transitionStatus, mount }) => (
          <>
            <Container
              key={location.href}
              mounted={mount || transitionStatus.startsWith("enter")}
              entryDirection={entry.state.direction}
              exitDirection={exit.state.direction}
            >
              <ContentMaxWidth>
                <LabelWrapper>
                  <Label text="Product Release" />
                </LabelWrapper>
                <BlogTitle>{post.title}</BlogTitle>
                <DateText>
                  {new Date(post.createdAt).toLocaleString("en-US", {
                    month: "short",
                    day: "2-digit",
                    year: "numeric",
                  })}
                </DateText>
                <BlogOptionsWrapper>
                  <Gallery
                    galleryArchiveZipUrl={post.gallery?.archiveZip?.file?.url}
                    gallery={post.gallery?.assets.map((g) => ({
                      ...g,
                      slug: post.slug,
                    }))}
                    trigger={
                      <IconWithLabelButton
                        label="gallery"
                        icon={
                          <GalleryIcon
                            strokeColor={
                              !!!post.gallery?.assets
                                ? colors.mediumGray
                                : colors.offBlack
                            }
                          />
                        }
                        disabled={!!!post.gallery?.assets}
                      />
                    }
                  />
                  <ShareWrapper savedUrl={savedUrl}>
                    <IconWithLabelButton
                      label="share"
                      icon={<ShareIcon />}
                      onClick={() => {
                        if (typeof navigator.share !== "undefined") {
                          navigator.share({
                            title: post.title,
                            text: `Read UAG article ${post.title} at ${
                              site.siteMetadata.siteUrl + "/" + post.slug
                            }`,
                            url: site.siteMetadata.siteUrl + "/" + post.slug,
                          })
                        } else {
                          if (typeof window !== "undefined") {
                            navigator.clipboard
                              .writeText(location.href)
                              .then(() => {
                                setSavedUrl(true)
                              })
                          }
                        }
                      }}
                    />
                  </ShareWrapper>
                  {/* {post.articlePdf?.file && (
                <IconWithLabelLink
                  label="pdf"
                  icon={<DownloadIcon />}
                  href={post.articlePdf.file.url}
                  download={post.articlePdf.file.fileName || true}
                />
              )} */}
                </BlogOptionsWrapper>

                <ArticleWrapper>
                  <ExploreImage
                    image={post.heroImage.fluid}
                    caption={post.heroImage.description}
                  />
                  {renderRichText(post.body, options)}
                </ArticleWrapper>
              </ContentMaxWidth>
              {next && (
                <ReflowBlockWrapper>
                  <ReflowBlock
                    subtitle="Next Article"
                    title={next.title}
                    onClick={triggerNext}
                  />
                </ReflowBlockWrapper>
              )}
            </Container>
          </>
        )}
      </TransitionState>
    </Layout>
  )
}

const ReflowBlockWrapper = styled.div`
  width: 100vw;
  margin-left: -20px;

  @media ${breakpoints.largerThanMobile} {
    margin-left: calc(1 / 12 * 100vw * -1);
  }
`
const ContentMaxWidth = styled.div`
  max-width: 1536px;
  margin: 0 auto;
`

const LabelWrapper = styled.div`
  width: max-content;
  margin: 20px 0;

  @media ${breakpoints.largerThanMobile} {
    margin: 30px 0;
  }
`

const IconLabelText = styled.p`
  ${smallcapsBold14Styles}
  margin-top: 3px;
  margin-left: 10px;

  color: inherit;
`

const IconWithLabelButtonContainer = styled.button<{ disabled?: boolean }>`
  display: flex;
  border: none;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  background: transparent;

  color: ${({ disabled }) => (disabled ? colors.mediumGray : colors.offBlack)};
`
const IconWithLabelLinkContainer = styled.a`
  display: flex;
  border: none;
  cursor: pointer;
  background: transparent;
`

const IconWrapper = styled.div`
  height: 20px;
  width: 20px;
`

function IconWithLabelButton({ icon, label, onClick, disabled }: any) {
  return (
    <IconWithLabelButtonContainer
      onClick={onClick}
      role="button"
      disabled={disabled}
    >
      <IconWrapper>{icon}</IconWrapper>
      <IconLabelText>{label}</IconLabelText>
    </IconWithLabelButtonContainer>
  )
}

function IconWithLabelLink({ icon, label, href, download }: any) {
  return (
    <IconWithLabelLinkContainer href={href} download={download}>
      <IconWrapper>{icon}</IconWrapper>
      <IconLabelText>{label}</IconLabelText>
    </IconWithLabelLinkContainer>
  )
}
