import ReactDOM from "react-dom"
import styled from "styled-components"
import React from "react"
//
import colors from "@constants/colors"
import { smallcapsBold14Styles } from "@constants/typography"
import { Close } from "@components/icons"
import ExploreImageGrid from "@components/ExploreImageGrid"
import breakpoints from "@constants/breakpoints"
import { DownloadIcon } from "@components/icons/Download"

const DownloadLinkWrapper = styled.div`
  display: flex;
`

const DownloadIconWrapper = styled.div`
  height: auto;
  width: 14px;
  margin-top: -2px;
  margin-right: 10px;
`

interface GalleryProps {
  trigger?: React.ReactElement
  gallery: any[]
  galleryArchiveZipUrl?: string
}

export default function Gallery({
  trigger,
  gallery,
  galleryArchiveZipUrl,
}: GalleryProps): React.ReactElement {
  const [expanded, setExpanded] = React.useState(false)

  React.useEffect(() => {
    document.body.style.overflow = expanded ? "hidden" : "auto"
    return () => {
      document.body.style.overflow = "auto"
    }
  }, [expanded])

  return (
    <>
      {expanded &&
        ReactDOM.createPortal(
          <GalleryContainer>
            <DownloadAndCloseWrapper>
              {galleryArchiveZipUrl && (
                <DownloadLinkWrapper>
                  <DownloadIconWrapper>
                    <DownloadIcon />
                  </DownloadIconWrapper>
                  <DownloadAllText href={galleryArchiveZipUrl} download>
                    Download all
                  </DownloadAllText>
                </DownloadLinkWrapper>
              )}
              <Button onClick={() => setExpanded(false)}>
                <Close />
              </Button>
            </DownloadAndCloseWrapper>
            <ImageGridWrapper>
              <ExploreImageGrid
                gallery={gallery}
                onRequestCloseSearch={() => setExpanded(false)}
              />
            </ImageGridWrapper>
          </GalleryContainer>,
          document.body
        )}
      {trigger &&
        React.cloneElement(trigger, { onClick: () => setExpanded(true) })}
    </>
  )
}

const GalleryContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  z-index: 1000;
  overflow: auto;
  background-color: ${colors.white};
  padding-bottom: 100px;
`

const DownloadAllText = styled.a`
  ${smallcapsBold14Styles}
`
const DownloadAndCloseWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px calc(1 / 24 * 100vw) 24px;

  @media ${breakpoints.mobileOnly} {
    border-bottom: 1px solid ${colors.dividerLineGray};
    margin-bottom: 40px;
  }

  @media ${breakpoints.laptop} {
    padding: 94px calc(1 / 24 * 100vw) 97px calc(2 / 24 * 100vw);
  }
`

const ImageGridWrapper = styled.div`
  padding: 24px calc(1 / 24 * 100vw) 24px;

  @media ${breakpoints.laptop} {
    padding: 0 calc(1 / 24 * 100vw) 0 calc(2 / 24 * 100vw);
  }
`

const Button = styled.button`
  display: flex;

  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;

  width: 42px;
  height: 42px;

  @media ${breakpoints.largerThanMobile} {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background: #e5e5e5;
    padding: 8px;
  }

  @media ${breakpoints.mobileOnly} {
    background-color: white;
  }

  :focus {
    outline: none;
  }
`
