import React from "react"
import styled from "styled-components"
import { paragraph18Styles } from "@constants/typography"
import colors from "@constants/colors"

const ListWrapper = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin: 18px 0;
`

export function UnorderedListBlock(props: {
  children?: React.ReactNode | React.ReactNode[]
}) {
  return <ListWrapper>{props.children}</ListWrapper>
}

const ListItemWrapper = styled.li`
  > span:first-child {
    color: ${colors.brandOrange};
    margin-right: 16px;
  }
`

const ListeItemText = styled.span`
  ${paragraph18Styles}
  font-weight: 400;
  color: ${colors.mediumDarkGray};
  white-space: pre-line;
`
export function ListItem(props: { text: string }) {
  return (
    <ListItemWrapper>
      <span>&mdash;</span>
      <ListeItemText>{props.text}</ListeItemText>
    </ListItemWrapper>
  )
}
